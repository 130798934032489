






































































































































































































import { defineComponent, reactive, toRefs, onMounted, computed } from '@vue/composition-api';
import axios from '@/common/axios';
import { did } from '@/common/const';
import { getLangCode } from '@/common/getLanguage';
import { getQuery } from '@/common/util';
import { immediateSendLog_growth, sendLog_growth, webGrowthLogger } from '@/common/log';
import img from '../googlePlay/components/img.vue';
const sendLog: Function = async(type:any, name:string, option: Object) => {
      immediateSendLog_growth({
              type,
              name,
              value:  {
                  ...option,
                  did,
              },
          });
};
export default defineComponent({
  components: { img },
    setup(props, ctx) {
      const state:Record<string, any> = reactive({ 
        kwai: ['AR', 'BO','BR','CA','CL','CO','CR','DO','EC','ES','GT','HN','MX','NI','PA','PE','PY','RU','SV','TR','UY','VE'],
        snack: ['BD', 'ID', 'LK', 'NP', 'PK', 'TH', 'IN'],
        kwaime: ['AE', 'BH', 'DZ', 'EG', 'IQ', 'JO', 'KW', 'LB', 'LY', 'MA', 'OM', 'QA', 'SA', 'SD', 'TN', 'YE'],
        kpn: 'KWAI',
        onelink: 'https://go.onelink.me/iGO2/kkwai',
      });
      
      const getKpn:Function = () => {
          const supportLang = (getQuery().language||getLangCode()).split('-');
          let kpn = ''
          for (let i = 0; i < supportLang.length; i++) {
            const element = supportLang[i];
            if(state.kwai.includes(element.toUpperCase())) {
              kpn='KWAI'
              state.onelink = 'https://go.onelink.me/iGO2/kkwai'
              break
            } else if(state.snack.includes(element.toUpperCase())) {
              kpn='SNACK'
              state.onelink = 'https://snackvideoactivity.onelink.me/1i1L/kkwai'
              break
            }
            else if(state.kwaime.includes(element.toUpperCase())) {
              kpn='KWAIME'
              state.onelink = 'https://kwaime.onelink.me/V16i/kkwai'
              break
            }
          }
          if(!kpn) return 'KWAI'
          return kpn
      }
      state.kpn = getKpn()
      const sendPVLog: Function = async(type:any, page:string, beginTime: number) => {
        let option: any = {
          type,
          page, // 设置当前进入页面的页面标识（在埋点平台注册，在 App 分析查看）
          params: {
            did,
            kpn: state.kpn,
            onelink: state.onelink
          },
        };
        if (beginTime) option = { ...option, beginTime };
        webGrowthLogger.sendImmediately('PV', option);
      };
      const beginTime = new Date().valueOf();
      sendPVLog('enter', 'SOCIAL_MEDIA_PAGE');
      const leavePageLog:Function = () => {
        // 触发 leave 事件
        sendPVLog('leave', 'SOCIAL_MEDIA_PAGE', beginTime);
      };

      const  evokeByTagA:Function = (uri:string)=> {
        const tagA = document.createElement('a');

        tagA.setAttribute('href', uri);
        tagA.style.display = 'none';
        document.body.appendChild(tagA);

        tagA.click();
      }

      

      const  jumpOnelink:Function = ()=> {
        if(state.kpn=='SNACK') {
          window.location.href = 'https://snackvideoactivity.onelink.me/1i1L/kkwai'
          // callApp({onelink:'https://snackvideoactivity.onelink.me/1i1L/kkwai', timeout: 0})
          // evokeByTagA('https://snackvideoactivity.onelink.me/1i1L/kkwai')
        } else if (state.kpn=='KWAI') {
          window.location.href = 'https://go.onelink.me/iGO2/kkwai'
          // callApp({onelink:'https://go.onelink.me/iGO2/kkwai', timeout: 0})
          // evokeByTagA('https://go.onelink.me/iGO2/kkwai')
          // window.location.href = 'intent://scan/#Intent;scheme=zxing;package=ccom.kwai.video;S.browser_fallback_url=http%3A%2F%2Fgo.onelink.me/iGO2/kkwai;end'
        } else if (state.kpn=='KWAIME') {
          window.location.href = 'https://kwaime.onelink.me/V16i/kkwai'
          // callApp({onelink:'https://kwaime.onelink.me/V16i/kkwai', timeout: 0})
          // evokeByTagA('https://kwaime.onelink.me/V16i/kkwai')
        }
      }

      // console.log(getBrowser())

      onMounted(()=>{
        document.addEventListener('visibilitychange', () => {
            const pageVisibility = document.visibilityState;
            // 页面变为不可见时触发 
            if (pageVisibility === 'hidden') {
                leavePageLog();
            }
        });
        window.onbeforeunload = window.onunload = function() {
            leavePageLog();
        };
        document.addEventListener('click', ()=>{
            // jumpOnelink()
        })
      }); 

      
      axios.post(`/rest/publish/oneLink/record`, {webDeviceId: did});
      // jumpOnelink()

      return { 
        ...toRefs(state),
        state,
        jumpOnelink,
        sendLog
      };
    },
});

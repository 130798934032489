import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import App from './index.vue';
import VueLazyload from 'vue-lazyload'  //引入这个懒加载插件

Vue.use(VueCompositionAPI);
Vue.use(VueLazyload)

Vue.config.productionTip = false;
new Vue({
    render: (h) => h(App),
}).$mount('#app');

